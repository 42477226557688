import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
  getModalVideoTemplate,
} from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";
import IconSvg from "../../Atoms/Svg";
import SoundIcon from "../../Atoms/SoundIcon";

import {
  NotoLarge,
  NotoBody,
  OswaldH1,
  OswaldH2,
  OswaldH4,
  OswaldH5,
} from "../../Atoms/Typography";

import { UIContext } from "../../../contexts/ui";

const ContentHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${scaleSpacing(16)};
  padding: 0 calc(var(--external-gutter));

  ${({ withPadding }) =>
    withPadding &&
    mediaquery.md(css`
      padding: 0 calc(var(--external-gutter) + var(--col));
    `)};

  ${mediaquery.md(css`
    flex-direction: row;
  `)};
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  :first-of-type {
    flex: 1;
  }

  ${({ showVideoButtons }) => css`
    ${mediaquery.md(css`
      max-width: ${showVideoButtons
        ? "calc(var(--col) * 17 - var(--gutter-size))"
        : "auto"};
    `)};
  `};
`;

const TitleWrapper = styled.div`
  margin-bottom: ${scaleSpacing(6)};
  width: calc(100% - (2 * var(--gutter-size)));
`;

const Title = styled.h1`
  color: ${colors.white};
  ${OswaldH2}
  max-width: 80%;

  ${mediaquery.md(css`
    ${OswaldH1}
  `)};
`;

const Subtitle = styled.div`
  ${OswaldH4}
  color: ${colors.white};
  width: 100%;

  ${mediaquery.md(css`
    ${OswaldH5}
    width: calc(var(--col) * 11 - var(--gutter-size));
  `)};
`;

const Description = styled.div`
  color: ${colors.white};
  ${NotoLarge}
  width: calc(100% - (2 * var(--gutter-size)));
  max-width: 100%;

  ${mediaquery.md(css`
    max-width: 90%;
  `)};

  img {
    width: 100%;
    height: auto;
  }

  .embed.embed-youtube,
  .wp-block-embed-youtube.wp-block-embed {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const IconLinks = styled.div`
  position: absolute;
  top: ${scaleSpacing(8)};
  right: var(--external-gutter);
  span {
    cursor: pointer;
    margin-left: ${scaleSpacing(6)};
  }
  ${mediaquery.md(css`
    top: ${scaleSpacing(14.5)};
    right: calc(var(--external-gutter) + var(--col));
    span {
      margin-left: ${scaleSpacing(6)};
    }
  `)};
`;

const TagList = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: ${scaleSpacing(2)};
  display: flex;
`;

const TagListItem = styled.li`
  display: flex;
  position: relative;
  margin: 0 ${scaleSpacing(4)} ${scaleSpacing(1)} 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    width: 1px;
    height: 9px;
    transform: translate(-8px, 10px);
    background-color: ${colors.mediumgrey3};
    position: absolute;
    right: -${scaleSpacing(4)};
    top: 0;
  }
  &:last-of-type:after {
    display: none;
  }
`;

const StyledTag = styled(Button)`
  ${NotoBody};
  line-height: ${rem(16)};
  background-color: transparent;
  color: ${colors.mediumgrey3};
  padding: ${scaleSpacing(1)} 0;
`;

const PlayButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${scaleSpacing(8)};

  ${mediaquery.md(css`
    margin: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: ${scaleSpacing(4)};
  `)};
`;

const PlayButton = styled(Button)`
  flex-shrink: 0;

  span {
    display: flex;
    margin-right: ${scaleSpacing(2.5)};
  }

  &:not(:first-of-type) {
    margin-top: ${scaleSpacing(6)};
  }

  ${mediaquery.md(css`
    &:not(:first-of-type) {
      margin-left: ${scaleSpacing(6)};
      margin-top: 0;
    }
  `)};
`;

const SplitButton = styled.span`
  color: ${colors.cinema};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${scaleSpacing(1)};
`;

const Informations = ({
  title,
  description,
  subtitle,
  duration,
  genre,
  category,
  country,
  year,
  video,
  trailer,
  ctaLabelTrailer,
  ctaLabelVideo,
  showVideoButtons,
  showDescription,
}) => {
  const { openModal } = useContext(UIContext);

  const showVideoModal = useCallback(
    (video, engage) => {
      video &&
        openModal({
          modalId: getModalVideoTemplate(video.player),
          modalParams: { video, engage },
        });
    },
    [openModal]
  );

  const showShareModal = useCallback(() => {
    openModal({ modalId: "share" });
  }, [openModal]);

  return (
    <>
      <ContentHead withPadding={!showDescription}>
        <ContentBlock showVideoButtons={showVideoButtons}>
          <TitleWrapper>
            <Title>
              <RichText data={title} clamp={3} />
            </Title>
            {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleWrapper>
          {(!!duration || !!genre || !!country || !!year) && (
            <TagList>
              {duration && (
                <TagListItem>
                  <StyledTag>{duration}</StyledTag>
                </TagListItem>
              )}

              {genre && (
                <TagListItem>
                  <StyledTag>{genre}</StyledTag>
                </TagListItem>
              )}

              {country && (
                <TagListItem>
                  <StyledTag>{country}</StyledTag>
                </TagListItem>
              )}

              {year && (
                <TagListItem>
                  <StyledTag>{year}</StyledTag>
                </TagListItem>
              )}
            </TagList>
          )}

          {showDescription && !!description && (
            <Description>
              <RichText data={description} />
            </Description>
          )}
        </ContentBlock>

        <ContentBlock>
          <IconLinks>
            <Button onClick={showShareModal} ariaLabel="share">
              <IconSvg name="share" size={24} height={24} fill={colors.white} />
            </Button>
          </IconLinks>
          {(video?.id || video?.id) && showVideoButtons && (
            <PlayButtonWrapper>
              {video?.id && (
                <PlayButton
                  buttonTheme="primary"
                  onClick={() => showVideoModal(video, { engage: true })}
                  ariaLabel="play"
                  css={css`
                    padding: ${scaleSpacing(2.5)} ${scaleSpacing(8.5)};
                  `}
                >
                  {category === "Podcast" ? (
                    <SoundIcon active color={colors.darkgrey3} />
                  ) : (
                    <IconSvg
                      size={6}
                      height={8}
                      name="play"
                      fill={colors.darkgrey2}
                    />
                  )}
                  {ctaLabelVideo}
                  {video.price !== "freemium" && (
                    <>
                      : <SplitButton> {video.price}€</SplitButton>
                    </>
                  )}
                </PlayButton>
              )}
              {trailer?.id && (
                <PlayButton
                  buttonTheme="secondary"
                  onClick={() => showVideoModal(trailer)}
                  ariaLabel="play"
                  css={css`
                    padding: ${scaleSpacing(2.5)} ${scaleSpacing(8.5)};
                  `}
                >
                  {category === "Podcast" ? (
                    <SoundIcon active color={colors.darkgrey3} />
                  ) : (
                    <IconSvg
                      size={6}
                      height={8}
                      name="play"
                      fill={colors.white}
                    />
                  )}
                  {ctaLabelTrailer}
                </PlayButton>
              )}
            </PlayButtonWrapper>
          )}
        </ContentBlock>
      </ContentHead>
    </>
  );
};

Informations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.array,
  video: PropTypes.object,
  trailer: PropTypes.object,
  duration: PropTypes.string,
  genre: PropTypes.string,
  category: PropTypes.string,
  country: PropTypes.string,
  year: PropTypes.number,
  showVideoButtons: PropTypes.bool,
  showDescription: PropTypes.bool,
  ctaLabelTrailer: PropTypes.string,
  ctaLabelVideo: PropTypes.string,
};

Informations.defaultProps = {
  showVideoButtons: true,
  showDescription: true,
};

export default React.memo(Informations);
